import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(
    private translate: TranslateService
  ) {
      this.translate.defaultLang = 'en'
  }

  languages = [
    {
      code: 'en',
      name: 'English',
      rtl: false
    },
    {
      code: 'id',
      name: 'Indonesia',
      rtl: false
    },
    {
      code: 'ar',
      name: 'Arabic العربية',
      rtl: true
    },
    {
      code: 'zh-cn',
      name: 'Chinese (Simplified)',
      rtl: false
    },
    // {
    //   code: 'zh-tw',
    //   name: 'Chinese Traditional',
    //   rtl: false
    // },
    {
      code: 'fr-fr',
      name: 'French',
      rtl: false
    },
    {
      code: 'de',
      name: 'German',
      rtl: false
    },
    {
      code: 'he',
      name: 'Hebrew',
      rtl: true
    },
    {
      code: 'hi',
      name: 'Hindi',
      rtl: false
    },
    {
      code: 'ja',
      name: 'Japanese',
      rtl: false
    },
    {
      code: 'kk',
      name: 'Kazakh',
      rtl: false
    },
    {
      code: 'ky',
      name: 'Kirghiz',
      rtl: false
    },
    {
      code: 'ko',
      name: 'Korean',
      rtl: false
    },
    {
      code: 'ms',
      name: 'Malaysia',
      rtl: false
    },
    {
      code: 'fa',
      name: 'Persian',
      rtl: false
    },
    {
      code: 'pt',
      name: 'Portugese',
      rtl: false
    },
    {
      code: 'pa',
      name: 'Punjabi',
      rtl: false
    },
    {
      code: 'ru',
      name: 'Russian',
      rtl: false
    },
    {
      code: 'es',
      name: 'Spanish',
      rtl: false
    },
    {
      code: 'th',
      name: 'Thai',
      rtl: false
    },
    {
      code: 'vi',
      name: 'Vietnamese',
      rtl: false
    },
  ];

  getDefaultLanguage(){
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    return language;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
  }

}
